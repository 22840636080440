<template>
  <div class>
    <headers />

    <div class="mb" style="padding:15px;padding-top: 70px !important;">
      <div class="logo">
        <img src="/img/cbreai_logo.png" />
      </div>
      <div class="first">
        <div class="input-box">
          <input
            class="form-control mr-1"
            style="width:650px;display:inline-block"
            type="text"
            @keyup.enter="search"
            :placeholder="cur_placeholder"
            v-model="keyword"
          />
          <button type="button" class="btn btn-white" style="display:inline-block" @click="search">
            <i class="fas fa-search" aria-hidden="true"></i>
            <div class="lang" v-html="lan.search"></div>
            <ctooltip
              v-if="lan_help.search!=null"
              :text="'<i class=\'mdi mdi-help-circle-outline\'></i>'"
              :key="'key_search'"
              :align="'right'"
              :help_text="lan_help.search"
            />
          </button>
        </div>

        <div class="types">
          <div class="custom-input">
            <input type="radio" value="company" v-model="type" />
            <div class="show-box radio"></div>
          </div> &nbsp; 
          <div class="lang" v-html="lan.search_by_firm_name"></div>
          <ctooltip
            v-if="lan_help.search_by_firm_name!=null"
            :text="'<i class=\'mdi mdi-help-circle-outline\'></i>'"
            :key="'key_search_by_firm_name'"
            :align="'right'"
            :help_text="lan_help.search_by_firm_name"
          />&nbsp;&nbsp;&nbsp;&nbsp;
          <div class="custom-input">
            <input type="radio" value="address" v-model="type" />
            <div class="show-box radio"></div>
          </div>&nbsp; 
          <div class="lang" v-html="lan.search_by_address"></div>
          <ctooltip
            v-if="lan_help.search_by_address!=null"
            :text="'<i class=\'mdi mdi-help-circle-outline\'></i>'"
            :key="'key_search_by_address'"
            :align="'right'"
            :help_text="lan_help.search_by_address"
          />&nbsp;&nbsp;&nbsp;&nbsp;
          <div class="custom-input">
            <input type="radio" value="building" v-model="type" />
            <div class="show-box radio"></div>
          </div>&nbsp; 
          <div class="lang" v-html="lan.search_by_building"></div>
          <ctooltip
            v-if="lan_help.building!=null"
            :text="'<i class=\'mdi mdi-help-circle-outline\'></i>'"
            :key="'key_building'"
            :align="'right'"
            :help_text="lan_help.building"
          />&nbsp;&nbsp;&nbsp;&nbsp;
          <div class="custom-input">
            <input type="radio" value="space" v-model="type" />
            <div class="show-box radio"></div>
          </div>&nbsp; 
          <div class="lang" v-html="lan.search_space"></div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <div class="custom-input">
            <input type="radio" value="company_" v-model="type" />
            <div class="show-box radio"></div>
          </div>&nbsp;
          <div class="lang" v-html="lan.company_search_2"></div>
        </div>
      </div>

      <!-- <div class="link-box">
        <h3>
          <div class="lang" v-html="lan.company"></div>
        </h3>

        <button
          class="two"
          type="button"
          @click="goto('/corp_list_group?title='+lan.firms_tend_to_relocate+'&type=knx')"
        >
          <div class="lang" v-html="lan.firms_tend_to_relocate"></div>
        </button>
        <button
          class="three"
          type="button"
          @click="goto('/corp_list_group?title='+lan.firms_tend_to_enhance+'&type=kz')"
        >
          <div class="lang" v-html="lan.firms_tend_to_enhance"></div>
        </button>
        <button
          class="four"
          type="button"
          @click="goto('/corp_list_group?title='+lan.firms_likely_to_be_consolidated+'&type=jy')"
        >
          <div class="lang" v-html="lan.firms_likely_to_be_consolidated"></div>
        </button>
        <button
          class="five"
          type="button"
          @click="goto('/corp_list_group?title='+lan.firms_tend_to_downsize+'&type=sx')"
        >
          <div class="lang" v-html="lan.firms_tend_to_downsize"></div>
        </button>
      </div>
      <div class="link-box">
        <h3>
          <div class="lang" v-html="lan.lease"></div>
        </h3>

        <button
          class="sex"
          type="button"
          @click="goto('/corp_list_group?title='+lan.offices_for_rent+'&type=rent')"
        >
          <div class="lang" v-html="lan.offices_for_rent"></div>
        </button>

         <button type="button" @click="goto(4)"><div class="lang" v-html="lan.space_likely_to_be_released"></div></button>
         <button type="button" @click="goto(5)"><div class="lang" v-html="lan.firms_office_likely_to_be_relocated"></div></button>
        
          <button type="button" @click="goto(8)"><div class="lang" v-html="lan.companies_with_good_short_term_performance"></div></button>
          <button type="button" @click="goto(9)"><div class="lang" v-html="lan.companies_with_good_medium_term_performance"></div></button>
          <button type="button" @click="goto(10)"><div class="lang" v-html="lan.to_expand"></div></button>
        
      </div>
      <div class="link-box">
        <h3>
          <div class="lang" v-html="lan.building"></div>
        </h3>

        <button
          type="button"
          class="seven"
          @click="goto('/corp_list_group?title='+lan.building_recommendation+'&type=building')"
        >
          <div class="lang" v-html="lan.building_recommendation"></div>
        </button>
      </div>
      <div class="link-box">
        <h3>
          <div class="lang" v-html="lan.feedback"></div>
        </h3>

        <button type="button" class="seven" @click="goto('/movingCases')">
          <div class="lang" v-html="lan.movingcases"></div>
        </button>
        <button type="button" class="seven" @click="goto('/move_plan')">
          <div class="lang" v-html="lan.move_plan"></div>
        </button>
      </div> -->

      <div class="bottom">
        <div class="lang" v-html="lan.language"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import router from "@/router";
import Headers from "@/components/Headers.vue";

//import { MarkerClusterer } from "@googlemaps/markerclusterer";
export default {
  name: "GoogleMaps",
  data() {
    const { currentRoute } = router;
    return {
      keyword: "",
      type:
        currentRoute.value.query.type == undefined
          ? "company"
          : currentRoute.value.query.type, // type is company or address
      lan: inject("global").language_data,
      lan_help: inject("global").language_help_data,
      cur_lan: "jp",
      show_menu: 1,
      cur_placeholder: "",
    };
  },
  components: {
    Headers,
  },
  mounted() {
    //console.log(inject('global').language_data);
    this.cur_lan = localStorage.getItem("cur_lan") != "en" ? "jp" : "en";
    //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data
  },
  $route() {
    this.cur_lan = localStorage.getItem("cur_lan") != "en" ? "jp" : "en";
    //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data
  },
  methods: {
    toggle_menu() {
      console.log(this.show_menu);
      this.show_menu = !this.show_menu;
    },
    goto(x) {
      if (x == 8) {
        location.href = "/#/corp1";
      } else if (x == 9) {
        location.href = "/#/corp2";
      } else if (x == 10) {
        location.href = "/#/corp3";
      } else if (x == 11) {
        location.href = "/#/corp4";
      } else if (x == 12) {
        location.href = "/#/corp5";
      } else if (x == 13) {
        location.href = "/#/corp9";
      } else {
        location.href = "/#" + x;
      }
      console.log(x);
    },
    search() {
      console.log(this.type);
      if (this.type == "space") {
        document.location.href =
          "/#/corp_list_group_space?keyword=" +
          this.keyword +
          "&type=" +
          this.type;
        return;
      }
      if (this.type == "company_") {
        document.location.href =
          "/#/stock_company_list?keyword=" +
          this.keyword +
          "&type=" +
          this.type;
        return;
      }
      if (this.type != "building") {
        document.location.href =
          "/#/search_result?keyword=" + this.keyword + "&type=" + this.type;
      }
       else {
        document.location.href =
          "/#/corp_list_group?title=物件リスト推奨&type=building&keyword=" +
          this.keyword;
      }
    },
    gotowindow(i) {
      var _that = this;
      if (this.cad != "") {
        _that.infowindow[this.cad.replace("k", "")].close();
      }
      this.cad = "k" + i;
      _that.infowindow[i].open({
        anchor: _that.markers[i],
        map: _that.map,
        shouldFocus: false,
      });
    },
  },
  watch: {
    $route() {
      const { currentRoute } = router;
      this.keyword =
        currentRoute.value.query.keyword == undefined
          ? ""
          : currentRoute.value.query.keyword;
      this.type = currentRoute.value.query.type =
        currentRoute.value.query.type == undefined
          ? "company"
          : currentRoute.value.query.type;
      this.cur_placeholder =
        this.type != "company"
          ? this.type != "address"
            ? this.lan.search_by_building
            : this.lan.search_by_address
          : this.lan.search_by_firm_name;
      this.cur_lan = localStorage.getItem("cur_lan") != "en" ? "jp" : "en";
      console.log(currentRoute.value.query);
      if (currentRoute.value.query.cur_lan != undefined) {
        this.cur_lan = currentRoute.value.query.cur_lan;
        let language_data = JSON.parse(localStorage.getItem("language_data"));
        this.cur_lan = localStorage.getItem("cur_lan") != "en" ? "jp" : "en";
        this.lan = language_data["menu_data_" + this.cur_lan];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-box {
  // width: 750px;
  margin: 5px auto;
}
.types {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}
.first {
  display: table;
  margin: 5px auto;
}
.link-box {
  width: 80%;
  margin: 30px auto 0 auto;
  display: table;
}
.link-box button {
  padding: 5px 10px;
  border-radius: 5px;
  background: #eee;
  border: 0;
  text-align: center;
  float: left;
  margin: 1% 2%;
}
.logo {
  width: 270px;
  height: 70px;
  margin: 5% auto;
  text-align: center;
}
.logo img {
  width: 270px;
}
h3 {
  display: block;
  width: 100%;
  color: #003f2d;
}
</style>
